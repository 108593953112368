// Variable overrides

$primary-color: #4638c2;
$secondary-color: #4638c2;
$grey: #4c4f54;
$theme-darkest: #2a2b36;


.nav>li>a:hover {
    background:lightgrey;
}
