::-webkit-scrollbar {
  width: 10px;
  background: rgba(0, 0, 21, 0.2)
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c6e7e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.map-search-results-dark {
    color: gray("100");
    background-color: theme-color("dark");
  }

.badge-darkgreen {
  background:#33a61c;
  color:white;
}

.badge-lightblue {
  background:#2b70cd;
  color:white;
}

body.loading, 
body.loading * {
  cursor: wait !important;
}

body.loading button, 
body.loading div {
  pointer-events: none;
}